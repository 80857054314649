import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import MirielHeader2 from './MirielHeader2';
import MirielFooter from './MirielFooter';

function Layout() {
    const [isFormExpanded, setIsFormExpanded] = useState(false);
    const navigate = useNavigate();

    const handleRequestAccessClick = () => {
        if (window.location.pathname === '/' || window.location.pathname === '/home-josh') {
            setIsFormExpanded(true);
        } else {
            navigate('/', { state: { expandForm: true } });
        }
    };

    return (
        <div>
            <MirielHeader2 onRequestAccessClick={handleRequestAccessClick} />
            <main>
                <Outlet context={[isFormExpanded, setIsFormExpanded]} />
            </main>
            <MirielFooter />
        </div>
    );
}

export default Layout;
