import React, { useState, useEffect, useRef } from 'react';
import styles from './MirielHeader2.module.css';
import { FaDiscord, FaLinkedin, FaEnvelope, FaBars, FaTimes } from 'react-icons/fa';
import { ReactComponent as MirielLogo } from './MirielLogoBlackNoText.svg';

const MirielHeader2 = ({ onRequestAccessClick }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const path = window.location.pathname;
    const shouldBeTransparent = path === '/' || path === '/home-josh';

    if (!shouldBeTransparent) {
      setIsScrolled(true);
    } else {
      const handleScroll = () => {
        setIsScrolled(window.scrollY > 0);
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleGetEarlyAccessClick = (e) => {
    e.stopPropagation(); // Prevents unintended behavior
    setIsMenuOpen(false); // Close the menu
    onRequestAccessClick();
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // This logic prevents the menu from closing when clicking outside
        // Comment this line if you don't want the menu to close when clicking outside
        // setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <header className={`${styles.header} ${isScrolled ? styles.scrolled : ''}`}>
      {isMobile ? (
        <div className={styles['mobile-header-container']}>
          <div className={styles.logoAndText}>
            <a href="/" className={styles.noStyleLink}>
              <MirielLogo className={styles.logoMobile} alt="Miriel Logo" />
            </a>
            <a href="/" className={styles.noStyleLink}>
              <span className={styles.brandName}>MIRIEL</span>
            </a>
          </div>
          <button className={styles.hamburgerButton} data-tracking-id="dropdown-navbar-mobile-menu" onClick={toggleMenu}>
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
          {isMenuOpen && (
            <nav className={styles.mobileNav} ref={menuRef}>
              {/*<a href="/learn" className={styles.link}>Learn</a>*/}
              <a href="/about" className={styles.link}>About</a>
              <a href="https://api.miriel.ai" className={styles.link}>Dashboard Sign In</a>
              <button
                type="button"
                className={styles['link2']}
                onClick={handleGetEarlyAccessClick}
              >
                Get Early Access
              </button>
              <a href="/about" className={styles.link}><FaEnvelope /> Contact Us</a>
              <a href="https://www.linkedin.com/company/miriel-ai" className={styles.link} target="_blank" rel="noopener noreferrer"><FaLinkedin /> Follow on LinkedIn</a>
              <a href="https://discord.gg/X9rJxBmuZW" className={styles.link} target="_blank" rel="noopener noreferrer"><FaDiscord /> Join Discord</a>
            </nav>
          )}
        </div>
      ) : (
        <div className={styles['header-container']}>
          <div className={styles.logoAndText}>
            <a href="/" className={styles.noStyleLink}>
              <MirielLogo className={styles.logo} alt="Miriel Logo" />
            </a>
            <a href="/" className={styles.noStyleLink}>
              <span className={styles.brandName}>MIRIEL</span>
            </a>
            <nav className={styles.navLinks}>
              {/*<a href="/learn" className={styles.link}>Learn</a>*/}
              <a href="/about" className={styles.link}>About</a>
              <div className={styles.connectLink} data-tracking-id="dropdown-navbar-desktop-connect">
                <span>Connect</span>
                <img src="arrowdown5.png" alt="Arrow" className={styles.arrow} />
                <div className={styles.dropdownContainer}>
                  <div className={styles.dropdownMenu}>
                    <a href="/about" className={styles.dropdownItem}>
                      <FaEnvelope /> <span className={styles.dropdownText}>Contact Us</span>
                    </a>
                    <a href="https://www.linkedin.com/company/miriel-ai" className={styles.dropdownItem} target="_blank" rel="noopener noreferrer">
                      <FaLinkedin /> <span className={styles.dropdownText}>Follow on LinkedIn</span>
                    </a>
                    <a href="https://discord.gg/X9rJxBmuZW" className={styles.dropdownItem} target="_blank" rel="noopener noreferrer">
                      <FaDiscord /> <span className={styles.dropdownText}>Join Discord</span>
                    </a>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <div className={styles.rightLinks}>
            <a href="https://api.miriel.ai" className={styles['cta-button2']}>
              <span>Dashboard Sign In</span>
            </a>
            <button type="button" className={styles['cta-button']} data-tracking-id="cta-beta-access-button-navbar" onClick={onRequestAccessClick}>
              <span>Get Early Access</span>
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default MirielHeader2;
