import React, { useEffect, useState, useRef } from 'react';
import { FaDiscord, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { useOutletContext, useLocation } from 'react-router-dom';
import styles from './NewHome2.module.css';
import { ReactComponent as MirielLogo } from './MirielLogoWhiteNoText.svg';
import { FaTimes } from 'react-icons/fa';  // Import the close icon


const NewHome2 = ({ backendUrl }) => {
    const [isFormExpanded, setIsFormExpanded] = useOutletContext();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    const [formData, setFormData] = useState({ name: '', email: '', linkedin: '' });
    const [message, setMessage] = useState('');
    
    const sciFiBoxRef = useRef(null);

    const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (isFormExpanded) {
            scrollToTop();
        }
    }, [isFormExpanded]);

    const location = useLocation();

    useEffect(() => {
        if (location.state?.expandForm) {
            setIsFormExpanded(true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location.state, setIsFormExpanded]);

    const handleButtonClick = () => {
        setIsFormExpanded(true);
        scrollToTop();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await fetch(`${backendUrl}/request-access`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const userId = await response.text();
                const emailResponse = await fetch(`${backendUrl}/send-confirmation`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (emailResponse.ok) {
                    setMessage(
                        <>
                            <p className={styles['highlight-text']}>Congrats, you're on the list!</p>
                            <p className={styles['subtext']}>Want to speed things up?</p>
                            <p className={styles['normal-text']}>Reply to the email we sent you with details about what you're building.</p>
                            <p className={styles['link-container']}>
                                <a href="https://www.linkedin.com/company/miriel-ai" className={styles.linkedin} target="_blank" rel="noopener noreferrer">
                                    <FaLinkedin /> <span>Follow on LinkedIn</span>
                                </a>
                                <span className={styles['link-separator']}></span> {/* Separator */}
                                <a href="https://discord.gg/X9rJxBmuZW" className={styles.discord} target="_blank" rel="noopener noreferrer">
                                    <FaDiscord /> <span>Join Discord</span>
                                </a>
                            </p>
                        </>
                    );
                    setIsFormSubmitted(true);
                } else {
                    throw new Error('Failed to send confirmation email');
                }
            } else {
                throw new Error('Failed to submit the form');
            }
        } catch (error) {
            console.error('Error submitting form or sending email:', error);
            setMessage('An error occurred. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseForm = () => {
        setIsFormExpanded(false);
    };
    
    useEffect(() => {
        const sections = document.querySelectorAll(`.${styles.section}`);
        const handleScroll = () => {
            sections.forEach((section) => {
                const sectionTop = section.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;
                if (sectionTop < windowHeight * 0.8) {
                    section.classList.add(styles.visible);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const sections = document.querySelectorAll(`.${styles['fade-in-wrapper']}`);
        const handleScroll = () => {
            sections.forEach((section) => {
                const sectionTop = section.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;
                if (sectionTop < windowHeight * 0.9) {
                    section.classList.add(styles.visible);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        <div>
            

            {/* Hero section */}
            <div className={styles['hero-container']} style={{ backgroundImage: `url('whitewave-crop2.png')` }}>
                <div className={styles['hero-title-container']}>
                    <h1 className={styles['hero-title']}>Weave The Future</h1>
                    <p className={styles['hero-sub-title']}>
                        Seamlessly integrate AI into your product within minutes
                    </p>
               </div>
                
                {/* Sci-fi box */}
                <div ref={sciFiBoxRef} className={`${styles['sci-fi-box']} ${isFormExpanded ? styles['expanded'] : ''}`} data-tracking-id="cta-beta-access-button-home-hero" onClick={() => setIsFormExpanded(!isFormExpanded)}>
                    {isFormExpanded ? (
                        isFormSubmitted ? (
                            <div className={styles['thank-you-message']}>
                                <p>{message}</p>
                                <button type="submit" className={styles['confirm-button']}>
                                    Will do!
                                </button>
                            </div>
                        ) : isLoading ? (
                            <div className={styles['loading-container']}>
                                <div className={styles['loading-dots']}>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <p className={styles['loading-text']}>Loading...</p>
                            </div>
                        ) : (
                            <div className={styles['form-container']} onClick={(e) => e.stopPropagation()}>
                                <FaTimes className={styles['close-icon']} onClick={handleCloseForm} />
                                <p className={styles['form-instructions']}>
                                    Hi there, we're Miriel. And you are?
                                </p>
                                <p className={`${styles['form-instructions']} ${styles['secondary']}`}>
                                    (We promise, no spam, just a beta waitlist)
                                </p>
                                <form data-tracking-id="cta-beta-access-form-home-hero" onSubmit={handleFormSubmit}>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="First and Last Name"
                                        className={styles['form-input']}
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        className={styles['form-input']}
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <input
                                        type="url"
                                        name="linkedin"
                                        placeholder="LinkedIn Profile (optional)"
                                        className={styles['form-input']}
                                        value={formData.linkedin}
                                        onChange={handleInputChange}
                                    />
                                    <button type="submit" className={styles['submit-waitlist-form-button']} data-tracking-id="cta-beta-access-form-button-home-hero">
                                        Let's Do This
                                    </button>
                                </form>
                            </div>
                        )
                    ) : (
                        <>
                            <div className={styles['home-logo']} style={{ textAlign: 'center' }}>
                                <MirielLogo className={styles['logo-image']} />
                                <h2 className={styles['logo-name']}>MIRIEL</h2>
                            </div>
                            <button className={styles['cta-button']}>
                                <span className={styles['shine-text']}>Get Early Access</span>
                            </button>
                        </>
                    )}
                </div>




            </div>


            {/* Content below hero section with background color #2f3134 */}
            
            <div className={styles['content-wrapper']}>
                <div className={styles['content-sections']}>
                    {isMobile ? (
                        <div className={styles['ai-integration-wrapper']}>
                            <h2 className={styles['body-header']}>Miriel's Dynamic RAG Platform weaves the right data into an LLM so you can build the apps and agents of your dreams</h2>

                            {/* Section 1 */}
                            <div className={`${styles['ai-section']} ${styles['alternate-layout']}`}>
                                <div className={styles['ai-section-number-header']}>
                                <div className={styles['ai-section-number']}>01</div>
                                <h2 className={styles['ai-section-header']}>LLMs Are Limited</h2>
                                </div>
                                <div className={styles['ai-code-block']}>
                            
                                <p className={styles['ai-code-line']}>
                                    <span className={styles['ai-code-comment']}>Using a Large Language Model (LLM) by itself offers limited potential</span>
                                </p>
                                <ul className={styles['ai-code-list']}>
                                    <li>An LLM is not personalized to your app and is difficult to control</li>
                                    <li>It is probabilistic and does not store facts or data</li>
                                    <li>An LLM is only as good as its training data and inputs</li>
                                </ul>
                                </div>
                            </div>

                            {/* Section 2 */}
                            <div className={styles['ai-section']}>
                                <div className={styles['ai-section-number-header']}>
                                <div className={styles['ai-section-number']}>02</div>
                                <h2 className={styles['ai-section-header']}>RAG Offers A New Hope</h2>
                                </div>
                                <div className={styles['ai-code-block']}>
                                
                                <p className={styles['ai-code-line']}>
                                    <span className={styles['ai-code-comment']}>Retrieval-Augmented Generation (RAG) <br/> can overcome LLM shortfalls by connecting data, but it introduces new challenges</span>
                                </p>
                                <ul className={styles['ai-code-list']}>
                                    <li>Each data source is unique; low retrieval quality degrades performance</li>
                                    <li>Setting up, maintaining, and scaling RAG infrastructure can take months</li>
                                    <li>Connecting your data is only half the battle, protecting your data is equally important</li>
                                </ul>
                                </div>
                            </div>

                            {/* Section 3 */}
                            <div className={`${styles['ai-section']} ${styles['alternate-layout']}`}>
                                <div className={styles['ai-section-number-header']}>
                                <div className={styles['ai-section-number']}>03</div>
                                <h2 className={styles['ai-section-header']}>Miriel Unlocks Rapid LLM-based Application Development</h2>
                                </div>
                                <div className={styles['ai-code-block']}>
                                
                                <p className={styles['ai-code-line']}>
                                    <span className={styles['ai-code-comment']}>Personalized and powerful AI through a simple API</span>
                                </p>
                                <ul className={styles['ai-code-list']}>
                                    <li><span className={styles['ai-code-keyword']}>Unparalleled Quality:</span>  <br/> Miriel's Dynamic RAG Platform uses intelligent indexing and retrieval tailored to each of your data sources</li>
                                    <li><span className={styles['ai-code-keyword']}>Power With Only 2 Lines of Code:</span>  <br/> 1 line adds your data sources; the 2nd line uses an LLM and the entire RAG pipeline to make intelligent queries – deploy your app quickly with all of this power baked in</li>
                                    <li><span className={styles['ai-code-keyword']}>Security and Control:</span>  <br/> Every interaction with your data can be encrypted; Manage permissions at the token level – not just file level</li>
                                    <li><span className={styles['ai-code-keyword']}>Auditability and Source Attribution:</span>  <br/> Inspect the entire RAG pipeline; <br/> no more AI black box</li>
                                </ul>
                                </div>
                            </div>

                            {/* Section 4 */}
                            <div className={styles['ai-section']}>
                                <div className={styles['ai-section-number-header']}>
                                <div className={styles['ai-section-number']}>04</div>
                                <h2 className={styles['ai-section-header-04']}>The Next Generation of App Development is Here</h2>
                                </div>
                                <div className={styles['ai-code-block']}>
                                
                                <p className={styles['ai-code-line']}>
                                    <span className={styles['ai-code-comment']}>Build, Measure, Learn – Like never before with Miriel</span>
                                </p>
                                <ul className={styles['ai-code-list']}>
                                    <li>By managing the data fed into the LLM and structuring the output, the LLM becomes a general-purpose library you control and call as needed</li>
                                    <li>Significantly reduce the complexity of your app while increasing functionality (1,000+ lines of code become 10’s)</li>
                                    <li>Test ideas, iterate faster, go-to-market with ease, take on more projects</li>
                                    <li>Deliver new capabilities and an unforgettable experience to your users</li>
                                </ul>
                                </div>
                            </div>
                        </div>

                    ) : (

                        <div className={styles['ai-integration-wrapper']}>
                            <div className={styles['body-header-container']}> 
                                <h2 className={styles['body-header']}>Miriel's Dynamic RAG Platform weaves the right data into an LLM so you can build the apps and agents of your dreams</h2>
                            </div>

                            {/* Section 1 */}
                            <div className={`${styles['ai-section']} ${styles['alternate-layout']}`}>
                                <div className={styles['ai-section-number-header']}>
                                <div className={styles['ai-section-number']}>01</div>
                                <h2 className={styles['ai-section-header']}>LLMs Are Limited</h2>
                                </div>
                                <div className={styles['ai-code-block']}>
                               
                                <p className={styles['ai-code-line-01']}>
                                    <span className={styles['ai-code-comment']}>Using a Large Language Model (LLM) by itself offers limited potential</span>
                                </p>
                                <ul className={styles['ai-code-list']}>
                                    <li>An LLM is not personalized to your app and is difficult to control</li>
                                    <li>It is probabilistic and does not store facts or data</li>
                                    <li>An LLM is only as good as its training data and inputs</li>
                                </ul>
                                </div>
                            </div>

                            {/* Section 2 */}
                            <div className={styles['ai-section']}>
                                <div className={styles['ai-code-block']}>
                                
                                <p className={styles['ai-code-line-02']}>
                                    <span className={styles['ai-code-comment']}>Retrieval-Augmented Generation (RAG) <br/> can overcome LLM shortfalls by connecting data, but it introduces new challenges</span>
                                </p>
                                <ul className={styles['ai-code-list']}>
                                    <li>Each data source is unique; low retrieval quality degrades performance</li>
                                    <li>Setting up, maintaining, and scaling RAG infrastructure can take months</li>
                                    <li>Connecting your data is only half the battle, protecting your data is equally important</li>
                                </ul>
                                </div>
                                <div className={styles['ai-section-number-header']}>
                                <div className={styles['ai-section-number']}>02</div>
                                <h2 className={styles['ai-section-header']}>RAG Offers A New Hope</h2>
                                </div>
                            </div>

                            {/* Section 3 */}
                            <div className={`${styles['ai-section']} ${styles['alternate-layout']}`}>
                                <div className={styles['ai-section-number-header']}>
                                <div className={styles['ai-section-number']}>03</div>
                                <h2 className={styles['ai-section-header']}>Miriel Unlocks Rapid LLM-based Application Development</h2>
                                </div>
                                <div className={styles['ai-code-block']}>
                                
                                <p className={styles['ai-code-line-03']}>
                                    <span className={styles['ai-code-comment']}>Personalized and powerful AI through a simple API</span>
                                </p>
                                <ul className={styles['ai-code-list']}>
                                    <li><span className={styles['ai-code-keyword']}>Unparalleled Quality:</span> Miriel's Dynamic RAG Platform uses intelligent indexing and retrieval tailored to each of your data sources</li>
                                    <li><span className={styles['ai-code-keyword']}>Power With Only 2 Lines of Code:</span> 1 line adds your data sources; the 2nd line uses an LLM and the entire RAG pipeline to make intelligent queries – deploy your app quickly with all of this power baked in</li>
                                    <li><span className={styles['ai-code-keyword']}>Security and Control:</span> Every interaction with your data can be encrypted; Manage permissions at the token level – not just file level</li>
                                    <li><span className={styles['ai-code-keyword']}>Auditability and Source Attribution:</span> Inspect the entire RAG pipeline; <br/> no more AI black box</li>
                                </ul>
                                </div>
                            </div>

                            {/* Section 4 */}
                            <div className={styles['ai-section']}>
                                <div className={styles['ai-code-block']}>
                                
                                <p className={styles['ai-code-line-04']}>
                                    <span className={styles['ai-code-comment']}>Build, Measure, Learn – Like never before with Miriel</span>
                                </p>
                                <ul className={styles['ai-code-list']}>
                                    <li>By managing the data fed into the LLM and structuring the output, the LLM becomes a general-purpose library you control and call as needed</li>
                                    <li>Significantly reduce the complexity of your app while increasing functionality (1,000+ lines of code become 10’s of lines)</li>
                                    <li>Test ideas, iterate faster, go-to-market with ease, take on more projects</li>
                                    <li>Deliver new capabilities and an unforgettable experience to your users</li>
                                </ul>
                                </div>
                                <div className={styles['ai-section-number-header']}>
                                <div className={styles['ai-section-number']}>04</div>
                                <h2 className={styles['ai-section-header']}>The Next Generation of App Development is Here</h2>
                                </div>
                            </div>
                        </div>
                    )}


                    {/* Get Early Access Button */}
                    <div className={styles['cta-container']}>
                        <button className={styles['cta-button']} data-tracking-id="cta-beta-access-button-home-bottom" onClick={handleButtonClick}>
                            <span className={styles['shine-text']}>Get Early Access</span>
                        </button>                    
                    </div>
                   

                </div>
            </div>
        </div>

    );
};

export default NewHome2;
