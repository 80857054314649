import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Layout from './Layout';
import NewHome2 from './NewHome2';
import NewAbout from './NewAbout';

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Set page title based on route
    const routeTitles = {
      '/': 'Miriel - Seamlessly integrate AI into your product within minutes',
      '/about': 'About - Miriel',
    };
    const defaultTitle = 'Miriel - Seamlessly integrate AI into your product within minutes';

    document.title = routeTitles[location.pathname] || defaultTitle;

    // Avoid duplicate page_view events by tracking the last recorded path
    const lastPagePath = window.lastPagePath || '';
    if (location.pathname !== lastPagePath) {
      window.lastPagePath = location.pathname; // Update the last recorded path

      // Manually push page_view event to dataLayer
      // Also had to set send_page_view: false for the Google tag inside of the GTM UI to avoid duplicate counting
      // https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtag
      // https://developers.google.com/analytics/devguides/collection/ga4/single-page-applications?implementation=browser-history
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'page_view',
          page_location: window.location.href,
          page_path: location.pathname,
          page_title: document.title
        });
      }
    }
  }, [location]);

  return null;
};

export default function App() {
  // Use environment variables for backend and frontend URLs
  const backendUrl = process.env.REACT_APP_APP_HOSTNAME_URL;
  const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

  if (!backendUrl) {
    console.error(
      'Error: Missing environment variables. Ensure REACT_APP_APP_HOSTNAME_URL and REACT_APP_FRONTEND_URL are defined in the .env files.'
    );
    return null;
  }

  return (
    <Router>
      <RouteChangeTracker />
      <Routes>
        <Route path="/" element={<Layout backendUrl={backendUrl} frontendUrl={frontendUrl} />}> {/* Layout wraps all other pages */}
          <Route index element={<NewHome2 backendUrl={backendUrl} frontendUrl={frontendUrl} />} />
          <Route path="/about" element={<NewAbout backendUrl={backendUrl} frontendUrl={frontendUrl} />} />
          {/* Uncomment and update additional routes as needed */}
          {/* 
          <Route path="/learn" element={<Learn3 backendUrl={backendUrl} frontendUrl={frontendUrl} />} />
          <Route path="/home-josh" element={<HomeJosh backendUrl={backendUrl} frontendUrl={frontendUrl} />} />
          <Route path="/new-home" element={<NewHome backendUrl={backendUrl} frontendUrl={frontendUrl} />} />
          <Route path="/old-home" element={<Home backendUrl={backendUrl} frontendUrl={frontendUrl} />} />
          */}
        </Route>
      </Routes>
    </Router>
  );
}
